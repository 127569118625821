ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
  background: #dfe6e4;
  position: relative;
}

.copyright-area{
  background: #dfe6e4;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a{
  color: #ff5e14;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

@media screen and (max-width:768px) {
.copyright-area{
  padding: 0;
}

}
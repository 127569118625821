.head {
    padding: 20px 0;
    color: #fff;
    margin-left: 10px;
}

.logo h1 {
    font-size: 35px;
    font-weight: 900;
}

.logo-img {
    max-width: 60px; 
    height: auto;
}
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo-text {
    margin-left: 10px; 
  }
  
  .logo-text-primary {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 0;
    color: #f3c83a;
  }
  
  .logo-text-secondary {
    font-size: 13px;
    color: #f3c83a;
  }
  
  
  
/* header */
header {
    background-color: #699646;
    margin: 0 30px;
    position: relative;
    z-index: 1;
}

header ul {
    padding: 30px 20px;
}

header li {
   margin-right: 40px;
}

header ul li a {
    color: #fff;
    font-weight: 500;
    transition: 0.5s;
}

header ul li a:hover {
    color: #f3c83a;
}

.start {
    background-color: #fff;
    padding: 30px 70px;
    color: #f3c83a;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
}

.toggle {
    display: none;
}


@media screen and (max-width: 600px){
    .start{
        clip-path: none;
    }

    .head {
        padding: 2px 0;
    }

    header {
        margin: 0;
        background-color: #fff;
        position: relative;
    }

    header ul.flexSB{
        display: none;
    }

    header ul li {
        margin-bottom: 20px;
    }

    .toggle {
        display: block;
        background: none;
        color:#699646;
        font-size: 30px;
        position: absolute;
        right: 40px;
        top: -20px;
    }



    .mobile-nav {
        position: absolute;
        top: 7vh;
        left: 0;
        width: 100%;
        background-color: #699646;
        z-index: 2;
    }


.logo-text-primary {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #f3c83a;
}
.logo {
    margin-left: -50px;
}

.social {
    display: none;
}
}

.nav-link {
    text-decoration: none; 
    color: #699646;
    font-weight: bold; 
  }
  
  .nav-link:hover {
    color: #699646; 
  }
  





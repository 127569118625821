.financial-literacy {
    max-width: 100%;
    margin: 10px;
    padding: 20px;
    text-align: left;
    
  }


  #ContentTextinner {
    margin-bottom: 20px; /* Add spacing between each inner content */
  }

  
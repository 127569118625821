

  h2 {
    margin-top: 50px;
  }

  .icon-admin {
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
    color: #1f4158;
  }
  .icon_header {
    vertical-align: middle;
    width: 30%;
  }
  .icon-admin, .icon_header {
    margin-right: 5px;
  }
  
  .close_icon {
    color: red;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 260px 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'sidebar header header header'
      'sidebar main main main';
    height: 100vh;
  }
  
  /* Header  */
  .header-admin {
    grid-area: header;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.10);
  }
  
  .menu-icon {
    display: none;
  }
  /* End Header  */
  
/* Sidebar */
#sidebar {
  grid-area: sidebar;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
}

@media screen and (max-width: 768px) {
  .chart-container {
    width: 100% !important;
  }
}


  
  
  .sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;
  }
  
  .sidebar-title > span {
    display: none;
  }
  
  .sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
  }
  
  .sidebar-list {
    padding: 0;
    list-style-type: none;
  }
  
  .sidebar-list-item {
    padding: 20px 20px 20px 20px;
    font-size: 18px;
  }


  
  .sidebar-list-item:hover {
    background-color: #699646;
    cursor: pointer;
    color: #fff;
  }
  
  .sidebar-list-item > a {
    text-decoration: none;
    color: #1f4158;
  }
  
  .sidebar-responsive {
    display: inline !important;
    position: absolute;
    z-index: 12 !important;
  }
  
  /* Add these styles to your existing CSS file or create a new one */

/* Dropdown styles */
.sidebar-list-item {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
}

.sidebar-list-item:hover .dropdown-content {
  display: block;
}

.dropdown-content li {
  padding: 10px;
}

.dropdown-content a {
  color: #333;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Add these styles to your existing CSS file or create a new one */

/* Dropdown styles */
.sidebar-list-item {
    position: relative;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%; /* Make dropdown full-width */
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;
  }
  
  .sidebar-list-item:hover .dropdown-content {
    max-height: 200px; /* Set the max height based on your content */
  }
  
  .dropdown-content li {
    padding: 10px;
  }
  
  .dropdown-content a {
    color: #333;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  /* End Sidebar  */
  
  /* Enrollment */
  .search-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .filter-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .data-table th, .data-table td {
    border: 1px solid #424040;
    padding: 8px;
    text-align: left;
  }
  
  .data-table th {
    background-color: #0e639f;
  }

  .data-table  tbody {
    color: #000;
  }
  
  .data-table tbody tr:hover {
    background-color: #f5f5f5;
    color: #000;
  }
  
  .popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    position: relative;
    color: #000;
    margin: 0 auto;
  }
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
  }
  
  .action-buttons button {
    margin-right: 10px;
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px 10px; 
    font-size: 12px;
  }

  .custom-btn {
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .approve-btn {
    background-color: #4caf50;
    color: #fff;
    margin-top: 20px;
  }
  
  .reject-btn {
    background-color: #ff5555;
    color: #fff;
    margin-top: 20px;
  }

  .view-btn {
    padding: 5px 10px;
    font-size: 12px;
  }
  
  .button-space {
    margin-right: 10px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .pagination button {
    background-color: #1b405a;
    color: white;
    padding: 8px 12px;
    margin: 0 5px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }

  .details-heading {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .details-item {
    margin-bottom: 15px;
    text-align: justify;
  }
  
  .details-label {
    display: inline-block;
    width: 150px; 
    font-weight: bold;
  }

  .details-value {
    display: inline-block;
    width: 150px; 
    font-weight: normal;
  }

  .payment-status-label {
    text-align: left;
    margin-top: 50px;
    font-weight: bold;
  }
  
  .payment-image {
    max-width: 100%;
    margin-top: 5px; /* Add space below the image */
  }
  

  /* Main  */  
  .main-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: #000;
    background-color: #f8f7fa;
  }

  .main-container h3 {
    color: #000;
  }
  
  
  .main-title {
    display: flex;
    justify-content: space-between;
    color:#000;
  }
  
  .main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
  }
  
  .card:first-child {
    background-color: #2962ff;
  }
  
  .card:nth-child(2) {
    background-color: #ff6d00;
  }
  
  .card:nth-child(3) {
    background-color: #2e7d32;
  }
  
  .card:nth-child(4) {
    background-color: #d50000;
  }
  
  .card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card-inner > .card_icon {
    font-size: 25px;
  }
  
  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
  }
  
  /* End Main  */
  
  /* Hagit User Enrolled */

.popup-hagit {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 800px; /* Set a maximum width */
  height: 60%; /* Adjust the height as needed */
  max-height: 400px; /* Set a maximum height */
  overflow-y: auto;
}

.popup-content-hagit {
  text-align: center;
}

.close-popup-hagit {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.details-heading-hagit {
  margin-bottom: 20px;
}

.profile-photo-section-hagit {
  margin-bottom: 20px;
}

.profile-photo-hagit {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 10px;
}

.form-field-hagit {
  margin-bottom: 15px;
}

.form-label-hagit {
  display: block;
  margin-bottom: 5px;
}

.action-buttons-hagit {
  margin-top: 20px;
}

/* Media Query for Mobile Devices */
@media (max-width: 600px) {
  .popup {
    width: 90%;
  }
}

  
  /* Medium <= 992px */
    
  @media screen and (max-width: 992px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 0.2fr 3fr;
      grid-template-areas:
        'header'
        'main';
    }
  
    #sidebar {
      display: none;
    }
  
    .menu-icon {
      display: inline;
    }
  
    .sidebar-title > span {
      display: inline;
    }
  }
  /* Small <= 768px */
    
  @media screen and (max-width: 768px) {
    .main-cards {
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 0;
    }
  
    .charts {
      grid-template-columns: 1fr;
      margin-top: 30px;
    }

  }
  
  /* Extra Small <= 576px */
  
  @media screen and (max-width: 576px) {
    .hedaer-left {
      display: none;
    }
  }

  /* Add these styles in your CSS file or inline in the component */

.dropdown-btn {
  padding: 6px 8px 6px 1px;
  text-decoration: none;
  font-size: 20px;
  color: #363f46;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  display: inline-block;

}

.dropdown-btn:hover {
  color: #000;
}

.caret-icon {
  float: right;
  padding-right: 8px;
}

.dropdown-container {
  display: none;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  margin-top: 20px;
  color: #000;
}

.dropdown-container.active {
  display: block;
  max-height: 200px; /* Adjust as needed */
}

.dropdown-container:hover {
  color: #ffbf23;
}

.dropdown-item {
  padding: 15px;
  color: #aaaaaa;
  text-decoration: none;
  display: block;
  transition: color 0.3s ease-out;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
}

.dropdown-item:hover {
  background-color: #ffc107; /* Yellow background on hover */
  color: #fff; 
}

.dropdown-item.active {
  background-color: #007bff; /* Blue background for active dropdown item */
  color: #fff; /* White text color for active dropdown item */
}


.charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.chart-container {
  width: 48%; /* Adjust the width as needed */
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.filter-container label {
  margin-right: 5px; /* Adjust the margin as needed */
}

.filter-container select {
  width: 35% !important; /* Adjust the width as needed */
  padding: 8px;
  font-size: 14px;
}

.filter-container button {
  width: 35% !important; /* Adjust the width as needed */
  padding: 8px;
  font-size: 14px;
}


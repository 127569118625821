.admin-login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff;
  }
  
  .admin-login-form {
    max-width: 600px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #4caf50;
    color: #fff; 
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .admin-login-form h2 {
    margin-bottom: 20px;
  }
  
  .admin-login-form p {
    margin-top: -10px;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .admin-login-form label {
    display: block;
    margin-bottom: 8px;
  }
  
  .admin-login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
  .admin-login-form button {
    background-color: #fff;
    color: #4caf50;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
  }

  .success-message {
    color: #000;
    font-weight: bold;
    margin-top: 10px; 
}
  
  /* Add responsive styles for smaller screens */
  @media (max-width: 768px) {
    .admin-login-form {
      width: 90%;
    }
  }
  
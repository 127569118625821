.hero {
    background-image: url("./bg.jpg");
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    padding-top: 20%;
    padding-right: 50px;
    color: #fff;    
}

h1 {
    font-size: 50px;
}

.complaint-hero {
    margin-bottom: 25px;;
}

.hero #heading h3 {
    color: #fff;

}

.hero #heading {
    text-align: left;
    margin: 0;
    padding: 0;
}

.nav-link {
    text-decoration: none; 
    color: #699646;
    font-weight: bold; 
  }
  
  .nav-link:hover {
    color: #699646; 
  }

@media screen and (max-width:768px) {
    .marigin {
        height: 50vh;
    }    

    .hero {
        background-position: right;
        padding-top: 300px;
        height: 100vh;
    }

    .hero .row {
        width: 100%;
        margin-top: -25px;

    }

    h1 {
        font-size: 40px;
    }

    p {
        font-size: 15px;
        line-height: 20px;
    }

    .complaint-hero {
        margin-bottom: 5px;
        font-size: 28px;
    }

    .hero::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for the darkness */
        z-index: -1;
      }
}

.with-main-content {
    margin-bottom: 50px; /* Add space between Hero and MainContent */
  }






@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  overflow: auto;
}

.container {
  max-width: 85%;
  margin: auto;
}

.flexSB {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #fff !important;
  transition: 0.5s;
}

.icon:hover {
  cursor: pointer;
  color: #f3c83a;
}

a {
  text-decoration: none !important;
}

li {
  list-style-type: none;
}

button {
  border: none;
}

.row {
  width: 50%;
}

button {
  padding: 17px 30px;
  background-color: #fff;
  color: #95af81;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgba(0 0 0 /9%);
}

button i {
  margin-left: 20px;
}

button:hover {
  box-shadow: none;
}

.primary-btn {
  background-color: #f3c83a;
  color: #000;
  padding: 20px;
}

#heading {
  text-align: center;
  padding: 40px 0;
}

#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #95af81;
  text-transform: uppercase;
}

#heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
}

p {
  line-height: 30px;
  font-size: 18px;
}

.marigin{
  margin-top: 40.3%;
}

